<template>
  <div>
    <div class="mb-xl-margin-file-sign pl-8 pr-8">
      <v-row class="">
        <v-col class="text-left" cols="12">
          <h1 class="voluntary__title">Carta de retiro voluntario</h1>
        </v-col>
        <v-col cols="12" class="text-left">
          <p class="text-info-1 text-left mb-2">
            <strong>
              Pasos para la firma digital, tenga encuenta lo siguiente:
            </strong>
          </p>
          <p class="text-info-1 text-left mb-2">
            <strong> 1. </strong> En la parte inferior de esta página firme el
            documento en el <strong>RECUADRO AZUL</strong>.
          </p>
          <p class="text-info-1 text-left mb-2">
            <strong> 2. </strong> Preferiblemente genere la firma del documento
            desde un dispositivo móvil para una mayor facilidad en su firma.
          </p>
          <p class="text-info-1 text-left mb-2">
            3. De clic en  <strong>RADICAR</strong>, valide la información del documento y que
            este se encuentre firmado.
          </p>
          <p>4. Finalmente, de clic en <strong>FINALIZAR</strong> y complete el formulario.</p>
        </v-col>
        <v-col v-if="btnFinish" cols="6" class="text-left pt-0">
          <v-btn rounded block color="#466be3" @click="preview" outlined>
            <strong> PREVISUALIZAR PDF </strong>

            <v-icon class="pl-2"> mdi-eye </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card
        elevation="0"
        class="text-left px-4 px-lg-10 px-md-10 px-sm-10 px-xl-10  py-10 mt-10"
        style="border-radius: 15px"
      >
        <div v-html="form.htmlCopy"></div>

        <v-divider></v-divider>

        <v-row v-if="!btnFinish" class="mt-3" justify="initial">
          <v-col cols="12" xl="8" lg="8" md="8" sm="8"  class="text-left">
            <v-card elevation="0" style="border-radius: 15px">
              <v-card-text class="px-0">
                <v-row justify="center" class="mx-0">
                  <v-col
                    class="pb-0 px-0 mx-0"
                    cols="13"
                    md="6"
                    xs="6"
                    sm="6"
                    lg="6"
                    xl="6"
                  >
                    <v-btn
                      block
                      outlined
                      small
                      color="#3957B9"
                      @click="undoDesk"
                      >Deshacer</v-btn
                    >
                  </v-col>
                  <v-col
                    class="pb-0 pr-0 pl-0 pl-sm-3 pl-lg-3 pl-md-3 pl-xl-3 mx-0"
                    cols="13"
                    md="6"
                    xs="6"
                    sm="6"
                    lg="6"
                    xl="6"
                  >
                    <v-btn
                      outlined
                      color="#3957B9"
                      block
                      small
                      @click="clearDesk"
                      >limpiar</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row justify="center" class="mx-0">
                  <v-col
                    class="pt-3 px-0"
                    md="12"
                    xs="12"
                    sm="12"
                    lg="12"
                    xl="12"
                  >
                    <vueSignature
                      class="voluntary__sign"
                      ref="signature1"
                      :sigOption="optionSign"
                      :h="'150px'"
                    ></vueSignature>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>

      <!-- <v-row v-if="!btnFinish" class="mt-3" justify="end">
        <v-col cols="8" class="text-left">
          <v-card elevation="0" style="border-radius: 15px">
            <v-card-text>
              <p class="text-info-1 text-left mb-2">
                <strong>
                  Pasos para la firma digital, tenga encuenta lo siguiente:
                </strong>
              </p>
              <p class="text-info-1 text-left mb-2">
                <strong> 1. </strong> En la parte inferior de esta alerta firme
                el documento en el <strong>RECUADRO AZUL</strong>.
              </p>
              <p class="text-info-1 text-left">
                <strong> 2. </strong> Preferiblemente genere la firma del
                documento desde un dispositivo móvil para una mayor facilidad en
                su firma.
              </p>

              <v-row justify="center" class="mx-0">
                <v-col
                  class="pb-0 px-0 mx-0"
                  cols="10"
                  md="6"
                  xs="6"
                  sm="6"
                  lg="6"
                  xl="6"
                >
                  <v-btn block outlined small color="#3957B9" @click="undoDesk"
                    >Deshacer</v-btn
                  >
                </v-col>
                <v-col
                  class="pb-0 pr-0 pl-3 mx-0"
                  cols="10"
                  md="6"
                  xs="6"
                  sm="6"
                  lg="6"
                  xl="6"
                >
                  <v-btn outlined color="#3957B9" block small @click="clearDesk"
                    >limpiar</v-btn
                  >
                </v-col>
              </v-row>

              <v-row justify="center" class="mx-0">
                <v-col
                  class="pt-3 px-0"
                  md="12"
                  xs="12"
                  sm="12"
                  lg="12"
                  xl="12"
                >
                  <vueSignature
                    class="voluntary__sign"
                    ref="signature1"
                    :sigOption="optionSign"
                    :h="'150px'"
                  ></vueSignature>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions class="mx-3">
              <v-row class="mb-4">
                <v-col cols="6">
                  <v-btn
                    rounded
                    block
                    outlined
                    color="#466BE3"
                    @click="dialog.sign = false"
                  >
                    CANCELAR
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <v-btn
                    rounded
                    block
                    dark
                    color="#466BE3"
                    @click="nextValidate()"
                    class="voluntary-retirement__button"
                  >
                    RADICAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row> -->

      <v-row v-if="!btnFinish" class="mb-10 pb-10 mt-5">
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-btn
            rounded
            block
            outlined
            color="#466BE3"
            @click="redirectHome()"
          >
            CANCELAR
          </v-btn>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-btn
            rounded
            block
            dark
            color="#466BE3"
            @click="nextValidate()"
            class="voluntary-retirement__button"
          >
            RADICAR
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="btnFinish" justify="end" class="mb-10 pb-10 mt-3">
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-btn
            rounded
            block
            outlined
            color="#466BE3"
            @click="redirectHome()"
          >
            CANCELAR
          </v-btn>
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="6" sm="6">
          <v-btn
            rounded
            dark
            color="#466BE3"
            @click="dialog.confirmation = true"
            block
            >FINALIZAR
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog.init" max-width="600" persistent>
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <img src="../../assets/icon/alert.svg" width="80" height="80" />
          </v-layout>
          <v-btn
            @click="redirectHome()"
            icon
            x-small
            style="display: flex; left: 18px; bottom: 40px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="voluntary___title-dialog mb-5">
            Esta presentando su retiro voluntaria
          </p>
          <v-form ref="form">
            <p class="voluntary__txt-field mb-1 mt-2">Fecha de retiro *</p>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="form.date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :rules="[(v) => !!v || 'Este campo es requerido']"
                  v-model="form.date"
                  label="Fecha de retiro"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="form.date" no-title scrollable :min="now">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancelar
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(form.date)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <p class="voluntary__txt-field mb-1 mt-2">
              Razón por la cual renuncia *
            </p>
            <v-select
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form.reason"
              :items="listReason"
              label="Seleccione respuesta"
            ></v-select>
            <p class="voluntary__txt-field mb-1 mt-2 text-left">
              BRM quisiera contactarlo para hablar sobre su renuncia, estaría
              interesado? *
            </p>
            <v-select
              :rules="[(v) => !!v || 'Este campo es requerido']"
              v-model="form.contact"
              :items="listOptionContact"
              label="Seleccione respuesta"
            ></v-select>
            <p class="voluntary__txt-field mb-1 mt-2 text-left">
              Jefe inmediato *
            </p>
            <v-autocomplete
              color="#466be3"
              v-model="form.userId"
              :items="listUsers"
              label="Seleccione respuesta"
              item-text="user_full_name"
              return-object
              :rules="[(v) => !!v || 'Este campo es requerido']"
            ></v-autocomplete>

            <v-alert
              v-if="infoUsers.full_name == ''"
              text
              type="info"
              class="mt-3"
            >
              Seleccione un jefe inmediato
            </v-alert>
            <v-row v-else class="mt-2 mb-3">
              <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="mb-0 mt-0">
                <v-layout justify-center>
                  <v-avatar
                    style="
                      cursor: pointer;
                      border-radius: 50%;
                      border: 2px solid;
                      border-color: #466be3;
                    "
                    class="voluntary__img mt-2"
                    size="100"
                  >
                    <v-img :src="infoUsers.user_img"> </v-img>
                  </v-avatar>
                </v-layout>
              </v-col>
              <v-col cols="12" xl="8" lg="8" md="8" sm="8">
                <v-row>
                  <v-col class="text-left pl-2 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0 pr-12 pt-2 pb-2" cols="12">
                    <p class="mb-1 voluntary__data_title">
                      <i> Nombres completos </i>
                    </p>
                    <p class="mb-1 voluntary__data_txt camel-case">
                      {{ toTitleCase(infoUsers.user_full_name) }}
                    </p>
                    <v-divider></v-divider>
                  </v-col>
                  <v-col class="text-left pl-2 pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0 pr-12 pt-2 pb-2" cols="12">
                    <p class="mb-1 voluntary__data_title">
                      <i> Cargo </i>
                    </p>
                    <p class="mb-1 voluntary__data_txt camel-case">
                      {{ toTitleCase(infoUsers.profile_name) }}
                    </p>
                    <v-divider></v-divider>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-4">
            <v-col cols="6">
              <v-btn rounded block outlined color="#466BE3" @click="redirectHome()">
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                rounded
                block
                dark
                color="#466BE3"
                @click="saveForm()"
              >
                CONTINUAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.confirmation" max-width="500" persistent>
      <v-card>
        <v-card-title>
          <v-layout justify-center>
            <img src="../../assets/icon/alert.svg" width="80" height="80" />
          </v-layout>
          <v-btn
            @click="dialog.confirmation = false"
            color="red"
            icon
            x-small
            style="display: flex; left: 18px; bottom: 40px"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="voluntary___title-dialog">
            Retiro voluntario
          </p>
          <p class="voluntary___txt-dialog mb-0">
            Confirmo que deseo presentar mi renuncia voluntaria
          </p>
          <!-- <p v-if="noVariables != ''" class="voluntary___txt-dialog mb-0 mt-3">
            {{ noVariables }}
          </p> -->
        </v-card-text>
        <v-card-actions>
          <v-row class="mb-4">
            <v-col cols="6">
              <v-btn rounded block outlined color="#466BE3" @click="close()">
                CANCELAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                :loading="loadingAll"
                rounded
                block
                dark
                color="#466BE3"
                @click="saveVoluntary()"
              >
                CONTINUAR
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog fullscreen v-model="dialog.form">
      <v-card>
        <v-toolbar color="#466BE3" dense elevation="0">
          <v-toolbar-title>
            <p class="mb-0 voluntary__subtitle-form">
              Para finalizar, complete el formulario
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="closeFormAlert" color="#fff" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <Form :id="70" @close="closeForm()"></Form>
      </v-card>
    </v-dialog>

    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      :params="message.params"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import vueSignature from "vue-signature";
import Form from "../forms/Details.vue";

export default {
  components: {
    Alert,
    vueSignature,
    Form,
  },
  data() {
    return {
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
      btnFinish: false,
      dialog: { init: true, confirmation: false, form: false },
      listReason: ["Mejor oferta","No le gusta la compañía","Complejidad campaña","Condiciones laborales diferentes a las ofertadas","Relacionamiento y comunicación con el jefe","Vacíos en Formación","Proceso Disciplinario Iniciado","Retiro con evidencia de incumplimiento obligaciones laborales","Motivos personales"],
      listOptionContact: ["Si", "No"],
      listUsers: [],
      menu: false,
      loadingAll: false,
      now: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      form: {
        // html: '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Señores:</span></p><p><strong style="background-color: transparent; color: rgb(0, 0, 0);">BRM SAS</strong></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Bogota (Ciudad donde trabaja)</span></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Asunto: </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">Renuncia Voluntaria</strong></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Por medio de la presente presento a partir de la fecha (</span><strong style="background-color: transparent; color: rgb(255, 0, 0);">Formulario de retiro</strong><span style="background-color: transparent; color: rgb(0, 0, 0);">) mi renuncia voluntaria al cargo </span><strong style="background-color: transparent; color: rgb(0, 0, 255);">(Mr Chispa)</strong><span style="background-color: transparent; color: rgb(0, 0, 0);">.&nbsp;</span></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">La razón de mi renuncia es (</span><strong style="background-color: transparent; color: rgb(255, 0, 0);">Formulario de retiro</strong><span style="background-color: transparent; color: rgb(0, 0, 0);">)</span></p><p><br></p><p><br></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Atentamente</span></p><p><br></p><p><strong style="background-color: transparent; color: rgb(0, 0, 0);">Nombre: </strong><strong style="background-color: transparent; color: rgb(0, 0, 255);">(Mr Chispa)</strong></p><p><span style="background-color: transparent; color: rgb(0, 0, 0);">Documento de Identidad: </span><strong style="background-color: transparent; color: rgb(0, 0, 255);">(Mr Chispa)</strong></p><p><br></p><p><br></p>',
        html: '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Señores:</span></p> <p><strong style="background-color: transparent; color: rgb(0, 0, 0);">BRM SAS</strong></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">{{headquarters_city}}</span></p> <p><br></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Asunto: </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">Renuncia Voluntaria</strong></p> <p><br></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Por medio de la presente presento a partir de la fecha </span> {{date}} <span style="background-color: transparent; color: rgb(0, 0, 0);"> mi renuncia voluntaria al cargo </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">{{profile}}</strong><span style="background-color: transparent; color: rgb(0, 0, 0);">.&nbsp;</span></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">La razón de mi renuncia es: </span> {{reason}} </p> <p><br></p> <p><br></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Atentamente</span></p> <p><br></p> <p><strong style="background-color: transparent; color: rgb(0, 0, 0);">Nombre: </strong>{{full_name}}</p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);"><strong> Documento de Identidad: </strong> </span>{{document_number}}</p> <p><br></p> <p><br></p>',
        htmlCopy:
          '<p><span style="background-color: transparent; color: rgb(0, 0, 0);">Señores:</span></p> <p><strong style="background-color: transparent; color: rgb(0, 0, 0);">BRM SAS</strong></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">{{headquarters_city}}</span></p> <p><br></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Asunto: </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">Renuncia Voluntaria</strong></p> <p><br></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Por medio de la presente presento a partir de la fecha </span> {{date}} <span style="background-color: transparent; color: rgb(0, 0, 0);"> mi renuncia voluntaria al cargo </span><strong style="background-color: transparent; color: rgb(0, 0, 0);">{{profile}}</strong><span style="background-color: transparent; color: rgb(0, 0, 0);">.&nbsp;</span></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">La razón de mi renuncia es: </span> {{reason}} </p> <p><br></p> <p><br></p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);">Atentamente</span></p> <p><br></p> <p><strong style="background-color: transparent; color: rgb(0, 0, 0);">Nombre: </strong>{{full_name}}</p> <p><span style="background-color: transparent; color: rgb(0, 0, 0);"><strong> Documento de Identidad: </strong> </span>{{document_number}}</p> <p><br></p> <p><br></p>',
        file: null,
        check: null,
        date: null,
        reason: null,
        contact: null,
        userId: null,
      },
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
        params: null,
      },
      infoUsers: {
        profile_level: null,
        profile_name: "",
        profile_type: "",
        user_full_name: "",
        user_id: null,
        user_img: "",
        user_lastname: "",
        user_name: "",

        img: "",
        perfil: "",
        cc: "",
        scc: "",
        cliente: "",
        campaign: "",
        full_name: "",
      },
      imgSign: {
        signature: "",
      },
      urlPreview: null,
    };
  },
  watch: {
    "form.userId": function (n) {
      if (n) {
        this.infoUsers = n;
      } else {
        this.infoUsers = {
          profile_level: null,
          profile_name: "",
          profile_type: "",
          user_full_name: "",
          user_id: null,
          user_img: "",
          user_lastname: "",
          user_name: "",
        };
      }
    },
  },
  methods: {
    redirectHome(){
      this.$router.push({name: 'home'});
    },
    closeFormAlert() {
      this.message.dialog = true;
      this.message.sms = "Debe completar el siguiente formulario";
      this.message.title = "¡Oh no!";
      this.message.type = "error";
      this.message.redirect = "";
      this.message.params = null;
    },
    preview() {
      var dataHtml = {
        html: this.form.htmlCopy,
        preview: true,
        boss_id: this.form.userId.user_id,
      };
      // if (this.urlPreview != null) {
        this.loadingAll = true;
        Api.RetirementVoluntary()
          .uploadResignationHtml(this.token, dataHtml)
          .then((res) => {
            console.log(res.data.data);
            if (res.data.cod == 0) {
              this.message.dialog = true;
              this.message.sms =
                "El documento se abrirá en una nueva pestaña del navegador o se generará una descarga";
              this.message.title = "Excelente";
              this.message.type = "info";
              this.message.redirect = "";
              this.message.params = null;
              this.urlPreview = res.data.data;
              // this.idPreview = res.data.data.id;
              window.open(this.urlPreview, "_blank");
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loadingAll = false;
          });
      // }
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    getTemplate() {
      var data = {
        document_default: true,
        date: this.form.date,
        reason: this.form.reason,
      };
      this.loadingAll = true;
      Api.RetirementVoluntary()
        .getDocument(this.token, data)
        .then((res) => {
          this.loadingAll = false;
          if (res.data.cod == 0) {
            var copy = "";
            this.form.html = res.data.data;
            copy = res.data.data;
            this.form.htmlCopy = copy.replace("{{signature}}", "");
          }
        })
        .catch((error) => {
          this.loadingAll = false;
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    getBoss() {
      Api.RetirementVoluntary()
        .getBoss(this.token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.listUsers = res.data.data;
          }
        })
        .catch((error) => {
          this.loadingAll = false;
        });
    },
    saveForm() {
      if (this.$refs.form.validate()) {
        this.dialog.init = false;
        this.getTemplate();
      }
    },
    nextValidate() {
      console.log(this.$refs.signature1.save());
      this.imgSign.signature = this.$refs.signature1.save();
      var htmlImg =
        '<img src="' +
        this.imgSign.signature +
        '" width="300" alt="firma digital" />';
      var newHtml = this.form.html.replace("{{signature}}", htmlImg);
      console.log(newHtml);
      this.form.htmlCopy = newHtml;
      this.btnFinish = true;
    },
    clearDesk() {
      var _this = this;
      _this.$refs.signature1.clear();
    },
    undoDesk() {
      var _this = this;
      _this.$refs.signature1.undo();
    },
    saveVoluntary() {
      var data = {
        id: 70, // UTILITIES
        cedulas: this.infoToken.document,
      };

      Api.RetirementVoluntary()
        .saveForms(data)
        .then((res) => {
          console.log("Se registró el formulario");
        })
        .catch((error) => {
          this.loadingAll = false;
        });
      var dataHtml = {
        preview: false,
        html: this.form.htmlCopy,
        boss_id: this.form.userId.user_id,
      };
      this.loadingAll = true;
      Api.RetirementVoluntary()
        .uploadResignationHtml(this.token, dataHtml)
        .then((res) => {
          if (res.data.cod == 0) {
            this.dialog.confirmation = false;
            this.message.dialog = true;
            this.message.sms =
              "Pronto su jefe inmediato se contactará con usted para indicarle los siguientes pasos, al dar clic en cerrar, responda el siguiente formulario.";
            this.message.title = "Estamos muy apenados por su decisión";
            this.message.type = "info";
            this.message.redirect = "";
            this.message.params = null;
            this.dialog.form = true;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
  },
  created() {
    // this.getTemplate();
    this.getToken();
    // this.now = moment().format("YYYY-MM-DD");
  },
  mounted() {
    this.getBoss();
    // this.getFieldsCurrent();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.voluntary__subtitle-form {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: #fff;
}
.voluntary__sign {
  border: 1.3px solid;
  border-color: #3a57b9;
}
.voluntary___title-dialog {
  font-family: "AsapSemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}
.voluntary___txt-dialog {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 19px;
  text-align: center;
  color: #626262;
}
.voluntary__txt-field {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #353535;
}
.voluntary__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}
</style>
